.special_dash_color {
  background-color: #f9fbff !important;
  position: relative;
  /* width: 100%; */
  box-sizing: border-box;
}
.flex_wrap {
  flex-wrap: wrap;
}

.space_evenly {
  justify-content: space-evenly !important;
}

.graph_bubble {
  margin: 50px;
  padding: 50px;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 40%;
  height: 50%;
  min-height: 500px;
  background-color: white;
  font-family: "landingFontTwo";
  min-width: 674px;
}
.analytics_title {
  font-family: "landingFontTwo";
  color: black;
  padding: 10px;
  text-align: left;
  color: #1b1e3c;
  font-size: 25px;
  padding-left: 50px;
}

.analytics_subheader {
  color: #bec5d1;
  font-family: "landingFontTwo";
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 20px;
  padding-top: 10px !important;
}

.analytics_header {
  color: #161938;
  font-family: "section-font";
  text-align: left;
  padding: 50px;
  padding-bottom: 0px !important;
}

.bubble_dish_img {
  border-radius: 40px;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.special_colllor {
  color: black;
}

.mini_bubble_oc {
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 20px;
}

@media (max-width: 700px) {
  .graph_bubble {
    /* margin: 50px; */
    margin: 0px;
    padding: 0px;
    /* padding: 50px; */
    border-radius: 20px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    width: 100%;
    /* min-height: 500px; */
    margin-bottom: 10px;
    background-color: white;
    font-family: "landingFontTwo";
    min-width: 350px !important;
    min-height: 320px;
    /* min-width: 674px;*/
  }
  .special_dash_color {
    width: 100% !important;
    padding: 0px;
  }
  .container_nf {
    width: 100%;
  }

  canvas {
    min-height: 210px;
  }
  .pending_fields p:first-child {
    margin-left: 10px;
    margin-right: 40px;
  }
  .field {
    /* margin-right: 40px; */
    width: 170px;
    min-width: 170px;
    font-size: 15px;
  }
}
