.top_searchbar {
  display: flex;
  justify-content: space-between;
}

.zip_code {
  display: flex;
  align-items: center;
}
.fa-map-marker-alt {
  margin-left: 10px;
  margin-right: 13px;
  color: orange;
}
.p-font {
  font-size: 13px;
  font-family: "p-font";
}

.search_bar {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  background-color: #f3f3f3;
  /* margin-right: 30px; */
  border-radius: 20px;
  width: 60%;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.form_submit {
  display: flex;
  flex: 1;
}

.fa-search {
  color: #fd9b54;
  margin-right: 10px;
  font-size: 18px;
}

.submit_input {
  flex: 1;
  border: none;
  background: transparent;
  font-size: 15px;
}

.form_submit_btn {
  display: none;
  visibility: hidden;
}

.bootstrap_icon {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
