.order_cart_description {
  display: flex;
  font-family: "landingFontTwo";
  flex-wrap: wrap;
  min-width: 81.48px;
  margin-left: 7px;
  font-size: smaller;
}

.order_cart_description i {
  padding: 5px;
}

.order_cart_description i:first-child {
  margin-right: 5px;
}

.order_cart_description i:last-child {
  margin-left: 5px;
}

.full_description {
  margin-top: 40px;
  font-size: 18px;
  flex-wrap: wrap;
}

.dish_name_list {
  font-family: "landingFontTwo";
  max-width: 25%;
  margin-right: 3px;
  white-space: initial;
  font-size: smaller;
}

.fa-minus-circle,
.fa-plus-circle {
  cursor: pointer;
  color: rgb(255, 189, 65);
}
