@media (max-width: 1642.5px) {
  /* filtering alignement and scroll adjustment */
  .cuisine_filtering {
    justify-content: flex-start;
  }

  .cuisine_filtering.bakers {
    justify-content: center !important;
  }
}

@media (max-width: 1366.25px) {
  /* for cotf */
  .chefs_of_the_week {
    width: 70%;
    margin-left: 10%;
  }

  .chefs_of_the_week h1 {
    font-size: 22px;
  }
}

@media (max-width: 1225.25px) {
  /*for food grid */
  .food-grid {
    justify-content: flex-start;
  }
  .navItem_fix {
    width: 40%;
    /* margin-left: 1%;
    margin-right: 1%; */
  }
  /* .food-grid .navItem_fix:nth-child(2n + 1) {
    margin-left: 9%;
  } */
  .search_bar {
    /*search bar adj*/
    flex: 1;
    max-width: 550px;
    margin-right: 20%;
    margin-left: 20px;
  }
  .top_searchbar {
    margin-bottom: 20px;
  }
}

@media (max-width: 1151.11px) {
  .search_filtering {
    justify-content: flex-start !important;
  }
  .search_filtering .filter_bubble:first-child {
    margin-left: 40px;
  }
}

@media (max-width: 883.75px) {
  .food-grid {
    flex-direction: column; /* for food grid */
    justify-content: center;
  }

  .navItem_fix {
    /* margin: 20px 0px !important; */
    width: 65%;
  }

  /* for cotf */
  .chefs_of_the_week {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .dish_one {
    display: none;
  }

  .split {
    width: 50%;
  }

  .dish_card p {
    font-size: 10px;
  }

  .chefs_of_the_week .rhs_cotf {
    width: 40%;
  }
  .chefs_of_the_week .lhs_cotf {
    width: 60%;
  }
  .chefs_of_the_week p {
    font-size: 15px;
  }
}

@media (max-width: 843.75px) {
  /* Apply filter button */
  .apply_filter {
    font-size: 17px;
    padding: 5px 10px;
    bottom: 5px;
    right: 10px;
  }
}

@media (max-width: 500px) {
  .cuisine_filtering.bakers {
    justify-content: flex-start !important;
  }
  .card p {
    bottom: 2px !important;
  }
}
