.send_feedback p {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  background: white;
  padding: 10px 50px;
  border-radius: 10px;
  font-size: 12px;
  font-family: "landingFontTwo";
  text-align: center;
}
.space_evenly {
  justify-content: space-evenly;
}

.gray_bg {
  background-color: rgb(252, 252, 252);
  padding: 40px;
  border-radius: 20px;
}

.create_delivery_pool {
  border-radius: 10px;
  border: 1px solid black;
  cursor: pointer;
  padding: 10px 20px;
  width: 40%;
  margin-left: auto;
  margin-right: auto;
}

.create_delivery_pool:hover {
  transform: scale(1.05);
}

.group_modal .fa-times {
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 20px;
  color: red;
  cursor: pointer;
}
.group_modal {
  font-family: "landingFontTwo";
  position: fixed;
  width: 80%;
  height: 80%;
  top: 10%;
  left: 10%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  background-color: white;
  z-index: 50000;
  text-align: center;
}

.group_modal p {
  padding: 20px;
}

.group_order {
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 10px 20px;
  border: 1px solid blue;
  color: blue;
  cursor: pointer;
  font-family: "landingFontTwo";
  font-size: 12px;
  z-index: 40000;
}
.top_override_100 {
  top: 100px !important;
  right: 0px !important;
  left: unset;
}
.btn_dash {
  padding: 10px 20px;
  border: 1px solid black;
  border-radius: 10px;
  width: 20%;
  margin-bottom: 20px;
  font-family: "landingFontTwo";
  cursor: pointer;
  min-width: 150px;
  margin: 10px;
}

.order_cont {
  background-color: white;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  min-width: 350px;
  max-width: 350px;
  max-height: 600px;
  overflow-y: scroll;
  text-align: left;
  margin: 10px;
  position: relative;
}

.align-top {
  align-items: flex-start;
}
.dish_listing_cont {
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.bubble.flex {
  margin: 10px;
  padding: 20px;
  width: 70px;
  height: 70px;
  font-size: 12px;
  border-radius: 10px;
  justify-content: center;
  cursor: pointer;
  font-family: "landingFontTwo";
}
.align-center {
  align-items: center;
  justify-content: center;
  display: flex;
}
.good_bubble {
  border: solid 1px yellowgreen;
}
.finish_bubble {
  border: solid 1px orange;
  color: orange;
  font-family: "landingFontTwo";
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
}

.bad_bubble {
  border: solid 1px red;
}

.bubble.flex .fa-check {
  color: yellowgreen;
  font-size: 15px;
  margin-left: 50%;
  transform: translateX(-50%);
}

.bubble.flex .fa-times {
  color: red;
  font-size: 15px;
  margin-left: 50%;
  transform: translateX(-50%);
}
.small_image {
  width: 77px;
  height: 70px;
  border-radius: 50px;
  margin: 10px;
  margin-right: 20px;
}

.special_note_dash {
  color: red;
  font-family: "landingFontTwo";
  padding: 10px 30px;
  white-space: -moz-pre-wrap !important;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  white-space: -webkit-pre-wrap;
}

.recreate_button_deliv {
  padding: 10px 20px;
  cursor: pointer;
  font-family: "landingFontTwo";
  display: flex;
  align-items: center;
  justify-content: center;
}

.recreate_button_deliv:hover {
  transform: scale(1.05);
}

.chat_bubble {
  position: absolute;
  bottom: -50px;
  right: 100px;
  height: 20px;
  width: 20px;
  z-index: 5;
}

.send_feedback {
  position: absolute;
  right: -35px;
  top: 45%;
  display: flex;
  justify-content: center;
  align-content: center;
  transform: translateX(35%) translateY(-50%);
  z-index: 1;
  cursor: pointer;
}

.send_feedback:hover {
  animation: anim 7s;
}

@keyframes anim {
  0% {
    right: -35px;
  }
  5% {
    right: -20px;
  }
  100% {
    right: -20px;
  }
}
.container_nf {
  background-color: transparent;
}

.header_block {
  height: 100px;
  background-color: #fee1b5;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  border-radius: 20px;
  position: relative;
  flex-wrap: wrap;
}

.metrics {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 300px;
}

.card_dash {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* text-align: center; */
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 4%;
}

.metrics .card_dash:not(:last-child) {
  margin-right: 5%;
}

.metrics .card_dash:first-child {
  margin-left: 8%;
}

.bubble_img {
  background-color: #f9fbec;
  height: 50%;
  width: 50px;
  border-radius: 15px;
  padding: 14px;
}

.two_image {
  padding: 14px 11px;
}

.content {
  margin-left: 15px;
  text-align: start;
}

.subtitle {
  font-family: "landingFontTwo";
  font-size: 12px;
}

.card_dash h1 {
  font-family: "landingFontTwo";
  font-size: 25px;
}

.delivery_date {
  height: 70%;
  width: 15%;
  min-width: 220px;
  margin-top: auto;
  margin-bottom: auto;
  border: solid white 3px;
  background-color: #fee1b5;
  font-family: "landingFontTwo";
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  margin-right: 50px;
  position: relative;
}

.delivery_date .fas {
  padding: 10px;
  cursor: pointer;
}

.drop_down {
  width: 90%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  left: 5%;
  bottom: 0px;
  transform: translateY(100%);
  z-index: 20;
  /* animation: anim 900ms forwards 100ms; */
}
/* 
@keyframes anim {
  from {
  }
  to {
    bottom: -10%;
  }
} */

.drop_down.hidden {
  display: none;
}

.drop_down div {
  border-bottom: solid black 1px;
  background: #fee1b5;
  opacity: 0.9;
  cursor: pointer;
  width: 100%;
  padding-top: 3px;
  padding-bottom: 3px;
}

/* BEGINNING OF DASHBOARD */

.pending {
  margin-top: 90px;
  padding: 20px;
  background-color: #fefefe;
  border-radius: 20px;
  max-height: 1300px;
  overflow: auto;
  white-space: nowrap;
  max-height: 1500px;
}

.pending_title {
  font-family: "landingFontTwo";
  font-size: 20px;
  color: red;
  text-align: start;
}
.pending_fields {
  margin-top: 20px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  font-family: "landingFontTwo";
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 20px;
  /* overflow: auto; */
  /* white-space: nowrap; */
}

.pending_fields::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 5px;
  height: 7px;
}

.pending_fields::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.74);
}

.pending_fields p:first-child {
  margin-left: 50px;
  margin-right: 50px;
}

.pending_fields p:last-child {
  margin-right: 50px;
}

.order_items {
  border-bottom: solid gray 5px;
}

/* MAIN CONTENT DASHBOARD */
.pending_row {
  display: flex;
  align-items: center;
  position: relative;
  overflow: auto;
  /* justify-content: space-between; */
  white-space: nowrap;
  border-bottom: solid #c5c5c5 2px;
  padding: 20px;

  /* justify-content: space-between; */
}

.pending_row p:first-child {
  /* margin-left: 50px; */
  /* margin-right: 10px !important; */
  margin-right: 50px;
}

.dish_listing {
  margin-left: 0px !important;
}

.dish_listing {
  margin-right: 30px;
}

.quantity_listing_complete {
  margin-right: 10px;
}
.dish_wrapper {
  flex-direction: column;
  display: flex;
  position: relative;
}

.single_dish {
  display: flex;
  align-items: center;
  /* text-align: start; */
  position: relative;
  margin-bottom: 5px;
}

.order_status {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cooking_listing,
.finished_listing {
  padding: 10px 20px;
  margin: 0px 5px;
  border-radius: 10px;
  cursor: pointer;
  /* transform: translateX(65%); */
}
.cooking_listing:hover,
.cooking_listing.clicked {
  background-color: #fde0b4;
}

.finished_listing:hover,
.finished_listing.clicked {
  background-color: #fcb55f;
}

.cooking_listing {
  margin-right: 7px;
}

.fields {
  margin-right: 50px;
  width: 170px;
  min-width: 170px;
  word-wrap: break-word;
  white-space: normal;
  display: inline-block;
}

.fields.order_id {
  margin-right: 100px !important;
}

.pending_fields.scroll .field_dish_wrapper {
  text-align: left;
}

.dish_wrapper {
  text-align: left;
}

/* .fields {
  margin-right: 60px;
} */

/* .field {
  margin-right: 125px;
} */

/* COMPLETED ORDERS */

.field {
  margin-right: 50px;
  width: 170px;
  min-width: 170px;
  font-size: 15px;
}
.checkout_title {
  font-family: "landingFontTwo";
  font-size: 20px;
  color: #439948;
  text-align: start;
}

.order_section {
  position: relative;
  border-bottom: solid #c5c5c5 2px;
  display: flex;
  align-items: center;
}

.order_id_comp {
  display: inline-block;
  text-align: center;
  /* transform: translateX(50%); */
  font-size: 15px;
}

.dish_wrapper_comp {
  width: 90%;
  display: inline-block;
}

.scroll {
  overflow: auto;
  white-space: nowrap;
}

.dishhh {
  margin-left: 0px !important;
}

.order_checkout_tab {
  margin-left: 50px;
}

.field_dish_wrapper {
  /* width: 90%; */
  display: flex;
  /* justify-content: space-evenly; */
}

.red_b {
  background-color: rgb(255, 173, 144);
}

.green_b {
  background-color: rgb(203, 255, 126);
}

.show_details h3 {
  font-size: 15px;
}

.show_details p {
  font-size: 11.5px;
}

.show_details {
  position: fixed;
  overflow: scroll;
  margin-left: 25%;
  margin-right: 25%;
  width: 50%;
  top: 15%;
  height: 70%;
  background-color: moccasin;
  z-index: 10001;
  font-family: "landingFontTwo";
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.show_details p {
}

.fa-window-close {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
  color: red;
  font-size: 20px;
  padding: 10px;
}

.align-left {
  /* text-align: start; */
}

.order_section p,
.pending_row p,
.cooking_listing,
.finished_listing {
  font-family: "landingFontTwo";
}

.quantity_listing_complete,
.total_price {
  /* transform: translateX(-50%); */
}

.customer_details {
  cursor: pointer;
}
.customer_details:hover {
  text-decoration: underline;
}

@media (max-width: 1204px) {
  .send_feedback {
    right: 50%;
    bottom: -10px;
    top: auto;
    transform: translate(50%, -50%);
  }
  .send_feedback p {
    transform: rotate(0);
  }
  .send_feedback img {
    display: none;
  }
  .send_feedback:hover {
    animation: none !important;
  }
  .order_id_comp {
    /* transform: translateX(-30%); */
  }
  .dish_wrapper_comp {
    width: 80%;
  }
}

@media (max-width: 968px) {
  .delivery_date {
    margin-top: 20px;
  }
  .pending {
    margin-top: 110px;
  }
  .completed.pending {
    margin-top: 40px;
  }
  .dish_wrapper_comp {
    width: 70%;
  }
  .order_id_comp {
    margin-left: 0px;
  }

  .dashboard_note {
    position: absolute;
    right: 0px;
    text-align: left;
  }
}

@media (max-width: 768px) {
  .order_id_comp {
    transform: translateX(-2%);
  }
}

@media (max-width: 500px) {
  .metrics {
    width: 70%;
  }

  .gray_bg {
    background-color: rgb(254, 252, 252);
    padding: 40px;
    border-radius: 20px;
    margin-top: 200px;
  }

  .delivery_date {
    width: 50%;
    padding: 5px 10px;
    min-width: 150px;
  }

  .field_dish_wrapper {
    width: auto !important;
  }
  .dish_listing {
    margin-right: 70px;
  }
  .order_id_comp {
    margin-left: 50px;
    margin-right: 60px;
    transform: translateX(0%);
  }
  .order_total_dash {
    display: none;
  }
  /* .flex_cont_dash {
    flex-direction: column;
  } */
}

@media (max-width: 450px) {
  .pending {
    margin-top: 135px;
  }
  .gray_bg {
    padding: 10px;
  }
  .order_cont {
    min-width: 300px !important;
  }
  .gray_bg > .flex_cont {
    flex-direction: column;
  }
  .dashboard_note {
    max-width: 100%;
  }
  .delivery_date {
    margin-top: 55px;
  }
  .completed.pending {
    margin-top: 40px;
  }
  .cont_button_dash {
    flex-direction: column;
  }
}
