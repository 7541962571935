@font-face {
  font-family: "landingFont";
  src: url(../fonts/Anton-Regular.otf) format("opentype");
}

@font-face {
  font-family: "reey";
  src: url(../fonts/reey/Reey-Regular.otf) format("opentype");
}

@font-face {
  font-family: "p-font";
  src: url(../fonts/Graphit-Regular/Graphit-Thin.otf) format("opentype");
}

@font-face {
  font-family: "landingFontTwo";
  src: url(../fonts/Graphit-Regular/Graphit-Light.otf) format("opentype");
}

@font-face {
  font-family: "p-bold";
  src: url(../fonts/Graphit-Regular/Graphit-Bold.otf) format("opentype");
}

@font-face {
  font-family: "logo-font";
  src: url(../fonts/Graphit-Regular/Graphit-Medium.otf) format("opentype");
}

@font-face {
  font-family: "section-font";
  src: url(../fonts/Graphit-Regular/Graphit-Regular.otf) format("opentype");
}

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}
:root {
  --header-height: 3rem;
  /* COLORS */
  --search-color: #e6e3e3;
  --logo-one-color: #ff8c37;
  --logo-two-color: #0d5bc8;
  --logo-three-color: #d386fa;
  --footer-color: #1b1a18;
  --nav-color: #ffda93;

  /* FONT WEIGHT */
  --font-medium: 500;
  --font-semi-bold: 600;
  --font-large: 2rem;

  /* Margins */
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 2.5rem;
  --mb-6: 3rem;

  /* z index */
  --z-tooltip: 10;
  --z-fixed: 100;
}

/*GENERAL*/

body,
html {
  scroll-behavior: smooth;
  position: relative;
}

body {
  background-image: linear-gradient(to bottom, #ffd66c, #fc6f44);
  padding-bottom: 90px;
  /* background-color: white; */
  /* white?  */
  /* height: 100vh; */
}

.container_nf {
  /* background-image: linear-gradient(to bottom, #ffd66c, #fc6f44); */
  margin-top: 40px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  padding: 10px;
  text-align: center;
  border-radius: 40px;
  background-color: white;
  padding-bottom: 90px;
}

.container_nfs {
  margin-top: 40px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  padding: 10px;
  text-align: center;
  border-radius: 40px;
  background-color: white;
  padding-bottom: 90px;
}

.container_homechef {
  margin-top: 40px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 40px;
  text-align: center;
  border-radius: 40px;
  background-color: white;
}

.fa-arrow-alt-circle-left {
  cursor: pointer;
  color: var(--logo-one-color);
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translate(-50%, -50%);
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  background: transparent;
}

.fa-arrow-alt-circle-right {
  cursor: pointer;
  color: var(--logo-one-color);
  font-size: 50px;
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translate(50%, -50%);
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
}
.fa-arrow-alt-circle-right:hover,
.fa-arrow-alt-circle-left:hover {
  cursor: pointer;
}

a {
  color: unset;
  text-decoration: none;
  font-family: "landingFontTwo";
}

.align-items-center-h {
  display: flex;
  justify-content: center;
}
.image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.align-items-center-v {
  display: flex;
  align-items: center;
}

.fullheight {
  height: 100vh;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}

.inline {
  display: inline-block;
}

input:focus {
  outline: none;
}

.btn_btn {
  padding: 20px;
  border-radius: 20px;
  /* color: rgb(207, 150, 44); */
  color: black;
  background-color: white;
  /* border: solid var(--logo-one-color) 2px; */
}

.btn_btn:hover {
  background-color: var(--logo-one-color);
  color: white;
  transition: 400ms ease-in-out;
}

.container {
  height: calc(100vh - 75px);
  margin-top: 130px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

/* GRIDS */
.col-1 {
  width: 7%;
}
.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%; /*PUT this after a flex to ensure width is static and not dynamic according to content.*/
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}

.col-7 {
  width: 58%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

.flex_start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.background_light {
  background-color: #fbfbfb;
}
