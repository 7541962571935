.dish_ratings_p {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}

.nothing_msg {
  margin-top: 25%;
  transform: translateY(-50%);
  text-align: center;
  font-family: "landingFontTwo";
  font-size: 25px;
}
.main_popup {
  position: fixed;
  left: 7vw;
  width: 86vw;
  max-height: 90vh;
  top: 5vh;
  white-space: pre-wrap;
  overflow: scroll;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  opacity: 0.95;
  border-radius: 40px;
  box-shadow: rgb(85, 91, 255) 0px 0px 0px 3px, rgb(31, 193, 27) 0px 0px 0px 6px,
    rgb(255, 217, 19) 0px 0px 0px 9px, rgb(255, 156, 85) 0px 0px 0px 12px,
    rgb(255, 85, 85) 0px 0px 0px 15px;
  z-index: 500;
}

.overall_rating_div {
  font-family: "landingFontTwo";
  text-align: center;
  margin-bottom: 40px;
  margin-top: 10px;
}

.overall_rating_div > h1 {
  white-space: normal;
}

.dish_rating_cont {
  width: 40%;
  text-align: center;
  margin-bottom: 20px;
  padding: 20px;
  min-width: 346px;
}

.dish_rating_cont:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.note {
  color: red;
  font-family: "landingFontTwo";
  padding: 10px 30px;
  max-width: 340px;
  white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  white-space: pre-wrap; /* css-3 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  white-space: -webkit-pre-wrap; /* Newer versions of Chrome/Safari*/
}

.dish_rating_cont_header {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.dish_rating_cont_header {
  font-family: "landingFontTwo";
}

.dish_rating_photo {
  border-radius: 20px;
  height: 60px;
  min-width: 60px;
  width: 60px;
}

.message_rating {
  font-family: "landingFontTwo";
}

.submit_rating_popup {
  padding: 10px 20px;
  background-color: #ff8c37;
  color: white;
  cursor: pointer;
  width: 30%;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
  font-family: "landingFontTwo";
  text-align: center;
  margin-top: 10px;
  position: absolute;
  right: 10px;
  top: 50px;
  width: fit-content;
}

.submit_rating_popup:hover,
.submit_rating_popup_f:hover {
  transform: scale(1.05);
}

.submit_rating_popup_f {
  padding: 10px 20px;
  margin-left: 10px;
  background-color: #ff8c37;
  color: white;
  cursor: pointer;
  width: 30%;
  border-radius: 30px;
  text-align: center;
}

@media (max-width: 500px) {
  .submit_rating_popup {
    top: 0px;
    right: 0px;
    position: relative;
  }

  .dish_rating_cont_header h1 {
    font-size: 23px;
  }

  .chef_image_s {
    width: 70%;
  }
}
