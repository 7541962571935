.left_checkout_container {
  width: 61%;
  text-align: start;
  padding: 40px;
}

.right_checkout_container {
  width: 39%;
  text-align: start;
  position: relative;
  padding: 20px;
}

.delivery_address_checkout {
  margin-top: 20px;
  padding: 10px;
  margin-bottom: 20px;
}

.delivery_address_checkout h1 {
  margin-bottom: 10px;
  font-family: "landingFontTwo";
}

.delivery_functionality {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.delivery_times_for_date {
  font-family: "landingFontTwo";
  padding: 10px;
}

.label,
.radio_button_time,
.wrapper_pt {
  cursor: pointer;
}

.delivery_times_for_date h1 {
  margin-bottom: 10px;
}

.radio_time_selector {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.radio_time_selector input:not(:last-child) {
  margin-bottom: 10px;
}
.radio_time_selector input:first-child {
  margin-top: 10px;
}

.non-profile-aptNumber-input {
  margin-left: 0px !important;
  max-width: 257px;
}

.service_fee > p {
  font-family: "landingFontTwo";
  margin-top: 10px;
  font-size: 20px;
}

.phone_input_comp {
  padding-left: 55px !important;
}

.phone_input_span {
  position: relative;
  font-family: "landingFontTwo";
  left: 50px;
  padding: 5px;
  border-right: solid black 2px;
}

.phone_input_div {
  display: flex;
  align-items: center;
  transform: translateX(-40px);
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

.phone_number_checkout {
  font-family: "landingFontTwo";
  padding: 10px;
}

.phone_number_checkout h1 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.phone_input_c {
  width: 100%;
  padding: 15px;
  border-radius: 25px;
  border: solid rgb(223, 223, 223) 1px;
  font-family: "landingFontTwo";
  color: black;
  font-size: large;
}

.phone_input_c:focus {
  outline: none;
}

.payment_detail_checkout {
  padding: 10px;
  margin-top: 20px;
}

.payment_detail_checkout h1 {
  font-family: "landingFontTwo";
  margin-bottom: 10px;
}

.card_detail {
  font-family: "landingFontTwo";
  font-size: 15px;
  width: 50%;
  margin-right: 10px;
}

.card_detail p {
  margin-left: 10px;
}

.card_number_fill {
  width: 100%;
  min-width: fit-content;
  margin-top: 10px;
}

.main_card_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.card_detail_cvv {
  width: 20%;
  min-width: fit-content;
}

.card_detail_cvv p {
  margin-left: 10px;
  font-family: "landingFontTwo";
}

.card_detail_expiration {
  margin-top: 20px;
}

.card_detail_expiration p {
  margin-bottom: 10px;
}

/* RIGHT CONTAINER */

.checkout_cont {
  display: flex;
}
.right_checkout_container {
  padding: 40px;
  padding-left: 60px;
  background-color: #f4f3f1;
  width: 39%;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
}

.right_checkout_container > h1 {
  margin-top: 20px;
  font-family: "p-font";
}

.dish_photo_checkout {
  width: 80px;
  height: 60px;
  margin-right: 10px;
  min-width: 78.59px;
  object-fit: cover;
  border-radius: 20px;
  margin-bottom: 10px;
}

.dish_item_list_checkout {
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
  align-items: center;
  flex-wrap: wrap;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dish_name_checkout {
  font-family: "p-font";
  text-transform: uppercase;
  min-width: 140.42px;
  font-size: 18px;
}

.dish_deliveryDate_checkout {
  font-family: "landingFontTwo";
  text-transform: uppercase;
  color: red;
  font-size: 15px;
}

.checkout_btn {
  margin-top: 40px;
  font-family: "landingFontTwo";
  font-size: large;
  text-align: center;
  cursor: pointer;
  padding: 20px;
  border: 3px solid orange;
  border-radius: 20px;
}

.payment_type_radios {
  justify-content: space-between !important;
  display: flex;
  align-items: center;
  width: 100%;
}
.checkout_btn:hover {
  background-color: orange;
  color: white;
  transition: 300ms ease-in-out;
}

/* PROCESS */

.process_step {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.process_icon {
  width: 100%;
  height: 100%;
  background-size: contain;
}

.icon_wrapper_checkout {
  width: 28px;
  height: 28px;
  border-radius: 20px;
  background-color: rgb(218, 218, 218);
  padding: 5px;
  margin-right: 20px;
}

.select_deliv {
  justify-content: flex-start !important;
}

.mode_1 {
  padding: 20px;
  font-family: "landingFontTwo";
  font-size: 18px;
}

.checkout_address .location-search-input-homechef {
  font-size: large !important;
}
.checkout_address .homechef-location-btn {
  padding: 15px;
  border-radius: 25px !important;
  border: solid rgb(223, 223, 223) 1px !important;
  height: fit-content !important;
}

.float_check {
  position: relative !important;
}

.gray_b_c {
  background-color: #f4f3f1;
  width: 39% !important;
}

.checkout_address .flex_cont {
  flex: 1;
}

.deliv_warning {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-content: center;
}

.deliv_warning_label {
  font-family: "landingFontTwo";
}

.deliv_warning_input {
  margin-right: 10px;
  color: orange;
}
@media (max-width: 800px) {
  .container_homechef {
    flex-direction: column;
  }
  .left_checkout_container {
    width: 100%;
  }

  .right_checkout_container {
    width: 100%;
  }

  .card_detail {
    width: 100% !important;
    margin-bottom: 20px;
  }

  .card_detail_cvv {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .dish_data_checkout {
    width: 100px;
    min-width: 100px;
    font-size: 14px;
    /* min-width: 120px;
    max-width: 120px; */
  }
  .dish_name_checkout {
    min-width: fit-content !important;
  }
  .dish_data_checkout > h1 {
    font-size: 17px;
  }
  .extra-header-checkout {
    margin-left: 0px !important;
    font-size: 18px;
  }
  .dish_photo_checkout {
    margin-right: 0px !important;
  }
}
