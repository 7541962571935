.selector_date {
  padding: 10px 20px;
  font-size: 18px;
  min-width: 250px;
  border-radius: 10px;
  font-family: "landingFontTwo";
  z-index: 100;
  height: 50px;
}

.sorting_selector .css-e56m7-control {
  min-width: 250px !important;
}
.date_select_popup {
  position: absolute;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 100;
  border-radius: 20px;
  left: 0px;
  bottom: -5px;
  transform: translateY(100%);
  width: 30%;
  background-color: white;
  padding: 10px;
}

input[type="checkbox"] {
  zoom: 1.5;
}

.bazaar_dates p {
  font-size: 20px;
}
.bazaar_dates input {
  padding: 10px;
}
.button_filter_wrappers {
  position: relative;
  font-size: 20px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.sorting_selector {
  min-width: 250px;
}

.apply_dates {
  font-family: "landingFontTwo";
  padding: 10px 20px;
  border-radius: 20px;
  color: white;
  background-color: rgb(255, 186, 59);
  cursor: pointer;
}

.sorting_selector {
  width: 20%;
  height: 50px;
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  /* border-radius: 20px; */
  font-family: "landingFontTwo";
}

.sorting_selector .css-e56m7-control,
.sorting_selector .css-e56m7-control:hover,
.sorting_selector .css-cw0kh6-control,
.sorting_selector .css-cw0kh6-control:hover {
  height: 100% !important;
  border-radius: 10px !important;
  background-color: #eeeeee;
  padding: 10px 0px;
  /* color: black; */
  border-color: black !important;
}

.css-1wa3eu0-placeholder {
  color: black !important;
  font-size: 18px !important;
}

.sorting_selector .css-1wa3eu0-placeholder {
  transform: translateY(-75%) !important;
}

@media (max-width: 500px) {
  .sorting_selector {
    margin-left: 0px !important;
    margin-top: 20px;
  }
  .date_select_popup {
    transform: translateY(82%) !important;
  }
  .search_filtering_main {
    align-items: center;
    font-family: "landingFontTwo";
    padding: 1px;
    overflow: auto;
    white-space: nowrap;
    display: flex;
    justify-content: flex-start !important;
  }
  .search_filtering_main > div:first-child {
    margin-left: 37px;
  }
  .filter_bubble_main {
    font-size: 11px;
    padding: 10px 5px;
  }
  .css-1wa3eu0-placeholder,
  .css-4ljt47-MenuList > div {
    font-size: 16px;
  }
  .sorting_selector .css-1wa3eu0-placeholder {
    font-size: 20px;
  }
  /* .css-i0syzg-menu {
    width: 80%;
  } */
}
