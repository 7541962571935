body,
html {
  scroll-behavior: smooth;
  position: relative;
}

body {
  background-image: linear-gradient(to bottom, #ffd66c, #fc6f44);
  padding-bottom: 90px;
  min-height: 100vh;
  /* background-color: white; */
  /* white?  */
  /* height: 100vh; */
}

.contain {
  background-size: contain !important;
  padding: 10px;
}

.mini_float {
  position: relative;
  background-size: cover;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  transform: translate(-50%, -50%);
  animation: float 5s ease-in-out infinite;
  z-index: 5;
  width: 150px;
  height: 150px;
}

.grid_food .mini_float {
  transform: translate(0%, 50%);
  animation: float2 5s ease-in-out infinite;
}

.chef_float {
  background-size: cover;
  width: 200px;
  height: 200px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: float 5s ease-in-out infinite;
  z-index: 5;
}

@keyframes float {
  0% {
    transform: translate(-50%, calc(-50% + 0px));
  }
  50% {
    transform: translate(-50%, calc(-50% + 10px));
  }
  100% {
    transform: translate(-50%, calc(-50% + 0px));
  }
}

@keyframes float2 {
  0% {
    transform: translate(0%, calc(-15% + 0px));
  }
  50% {
    transform: translate(0%, calc(-15% + 10px));
  }
  100% {
    transform: translate(0%, calc(-15% + 0px));
  }
}

.dropdown,
.dropleft,
.dropright,
.dropup {
  position: relative;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show > .btn-secondary.dropdown-toggle {
  color: black;
  background-color: rgb(255, 206, 149);
  border-color: orange;
  font-family: "landingFontTwo";
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  font-family: "landingFontTwo";
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.5rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: "landingFontTwo";
}
button,
select {
  text-transform: none;
}
button,
input {
  overflow: visible;
}
button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}
button {
  border-radius: 0;
}

.dropdown-menu.show {
  display: block;
}
.dropdown-menu[x-placement^="bottom"],
.dropdown-menu[x-placement^="left"],
.dropdown-menu[x-placement^="right"],
.dropdown-menu[x-placement^="top"] {
  right: auto;
  bottom: auto;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover {
  background-color: rgb(255, 206, 149);
}
*,
::after,
::before {
  box-sizing: border-box;
}
