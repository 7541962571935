.form {
  position: absolute;
  width: 80%;
  top: 70px;
  border-radius: 30px;
  background-color: white;
  padding: 10px;
  text-align: start;
  transform: translateY(-56%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.none,
.hide {
  display: none;
}

.login_button_form {
  padding: 10px 20px;
  border-radius: 30px;
  border: 2px solid white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  font-family: "landingFontTwo";
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: yellowgreen;
  z-index: 500;
}

.cursor {
  cursor: pointer;
}

.cursor:hover {
  transform: scale(1.02) translateY(-56%) translateX(-1%);
}

.opacity_gray {
  opacity: 0.5;
}

.form_row_container {
  width: 100%;
  justify-content: space-evenly;
  display: flex;
}

.yes_button_f {
  padding: 10px 20px;
  border-radius: 30px;
  border: 1px solid black;
  cursor: pointer;
  margin-bottom: 10px;
}

.active_yes {
  background-color: rgb(139, 187, 42);
  transition: 100ms ease-in-out;
  color: white;
}

.active_no {
  background-color: rgb(255, 132, 83);
  color: white;
  transition: 100ms ease-in-out;
}

.no_baker {
  background-color: #00c5f7 !important;
}

.yes_button_f:not(:last-child) {
  margin-right: 10px;
}

.form_col {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}

.form p {
  font-size: 15px;
  font-family: "landingFontTwo";
  margin-bottom: 5px;
  color: black;
}

.chef_input_fields {
  width: 100%;
  position: relative;
}

.form_col .bubble_input {
  border: none;
  background-color: white;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  height: 40px;
  border: solid #7e7d7b 1px;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
}

.cursor .form_col .bubble_input {
  cursor: pointer;
}

.bubble_input::placeholder {
  font-family: "landingFontTwo";
}

.input_radios {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.bubble_input_radio {
  margin-top: 5px;
  margin-bottom: 10px;
  height: 40px;
  border-radius: 10px;
  font-family: "landingFontTwo";
  color: black;
  border: solid orange 1px;
  padding: 10px;
  cursor: pointer;
}
.phone_input_form {
  padding-left: 45px !important;
}

.phone_precursor {
  position: absolute;
  left: 10px;
  top: 50%;
  font-size: 14px;
}

.bubble_input_radio.active {
  background-color: rgb(255, 102, 0);
  border: solid white 1px;
  color: white;
  padding: 10px;
  cursor: pointer;
}

.chef_detail_submit {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  background-color: #fb613f;
  border: solid white 1px;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  transition: 100ms ease-in-out;
  font-family: "landingFontTwo";
}

.chef_detail_submit:hover {
  transform: scale(1.1);
}

@media (max-width: 500px) {
  .login_button_form {
    top: 10%;
  }
  .login_button_form:hover {
    transform: scale(1.03) translate(-51.5%, -51.5%);
  }
}
