.new_tag_fi {
  color: white;
  padding: 5px 10px;
  background-color: rgb(250, 176, 40);
  font-family: "landingFontTwo";
  width: fit-content;
  border-radius: 20px;
  margin-left: 15px;
}

.navItem_fix {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 286px;
  border-radius: 30px;
}

.banner_discount {
  position: absolute !important;
  margin-left: 0px !important;
  margin-bottom: 0px !important;
  top: 0px;
  left: 0px;
  width: 100%;
  text-align: center;
  padding: 3px;
  color: white !important;
  background-color: rgb(109, 218, 0);
  opacity: 0.85;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.food_card {
  width: 30%;
  margin: 0% 1%;
  min-width: 290px;
  margin-bottom: 28px;
  border-radius: 30px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  min-height: 310px;
}
.rating {
  margin-left: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.rating p {
  color: red !important;
  font-size: 15px;
}

.chef_image_photo {
  position: absolute;
  border: solid white 3px;
  border-radius: 40px;
  height: 70px;
  width: 70px;
  /* background-image: url(../../images/ranbir.jpg); */
  top: 60%;
  right: 0px;
  transform: translateY(-40%) translateX(-40%);
  z-index: 3;
}

.d_none {
  display: none;
  transition: 200ms ease-in-out;
}

.food_card h1 {
  font-family: "p-bold";
  margin-bottom: 3px;
  position: relative;
  z-index: 3;
  margin-left: 15px;
  font-size: 15px;
}

.food_card p {
  font-family: "landingFontTwo";
  color: gray;
  margin-bottom: 5px;
  position: relative;
  z-index: 3;
  margin-left: 15px;
}

.food_card:hover {
  transform: scale(1.03);
  transition: 200ms ease-in-out;
  cursor: pointer;
}

.food_card_img {
  position: relative;
  z-index: 2;
  flex: 1;
  width: 100%;
  max-height: calc(65% + 19.5px);
  /* background-image: url(images/beef.png); */
  background-size: contain !important;
}

/* .food_card_img_background {
  position: relative;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
} */

.food-grid .food_card:nth-child(3n) {
  margin-right: 0px;
}

.food-grid .food_card:nth-child(3n + 1) {
  margin-left: 2.8%;
}

@media (max-width: 1642.5px) {
  /* filtering alignement and scroll adjustment */
  .cuisine_filtering {
    justify-content: flex-start;
  }
}

@media (max-width: 1366.25px) {
  /* for cotf */
  .chefs_of_the_week {
    width: 70%;
    margin-left: 10%;
  }

  .chefs_of_the_week h1 {
    font-size: 22px;
  }
}

@media (max-width: 1225.25px) {
  /*for food grid */
  .food-grid {
    justify-content: flex-start;
  }
  .food_card {
    width: 40%;
    margin-left: 1%;
    margin-right: 1%;
  }
  .food-grid .food_card:nth-child(2n + 1) {
    margin-left: 9%;
  }
  .search_bar {
    /*search bar adj*/
    flex: 1;
    max-width: 550px;
    margin-right: 20%;
    margin-left: 20px;
  }
  .top_searchbar {
    margin-bottom: 20px;
  }
}

@media (max-width: 1151.11px) {
  .search_filtering {
    justify-content: flex-start !important;
  }
  .search_filtering .filter_bubble:first-child {
    margin-left: 40px;
  }
}

@media (max-width: 883.75px) {
  .food-grid {
    flex-direction: column; /* for food grid */
    justify-content: center;
  }

  .food_card {
    margin: 20px 0px !important;
    width: 65%;
  }

  /* for cotf */
  .chefs_of_the_week {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }

  .dish_one {
    display: none;
  }

  .split {
    width: 50%;
  }

  .dish_card p {
    font-size: 10px;
  }

  .chefs_of_the_week .rhs_cotf {
    width: 40%;
  }
  .chefs_of_the_week .lhs_cotf {
    width: 60%;
  }
  .chefs_of_the_week p {
    font-size: 15px;
  }
}

@media (max-width: 843.75px) {
  /* Apply filter button */
  .apply_filter {
    font-size: 17px;
    padding: 5px 10px;
    bottom: 5px;
    right: 10px;
  }

  .food_card p {
    font-family: "landingFontTwo";
    color: gray;
    margin-bottom: 5px;
    position: relative;
    z-index: 3;
    margin-left: 15px;
    font-size: 12px;
  }

  .new_tag_fi {
    font-size: 12px;
  }
}
