.location-search-input {
  width: 100%;
  height: 100%;
  font-family: "landingFontTwo";
  color: rgb(255, 140, 55);
  font-size: 15px;
  border: none;
  font-size: 14px;
}

.location-search-input-homechef {
  width: 100%;
  height: 100%;
  font-family: "landingFontTwo";
  color: black;
  font-size: 15px;
  border: none;
  font-size: 14px;
  z-index: 2000;
}

.autocomplete-dropdown-container .suggestion-item:first-child {
  margin-top: 18px;
}

.form.cursor .location-search-input-homechef {
  cursor: pointer;
}

.autocomplete-dropdown-container-homechef {
  background-color: white;
  opacity: 1;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
}

.location-btn {
  border: 5px solid rgb(255, 140, 55);
  position: relative;
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  min-width: 350px;
}

.homechef-location-btn {
  border: 1px solid black;
  position: relative;
  background-color: white;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  height: 50px;
  border: solid #7e7d7b 1px;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  z-index: 8;
}

.location-btn-profile {
  min-width: 250px !important;
}

.loading_loc {
  font-family: "landingFontTwo";
  color: rgb(255, 140, 55);
  font-size: 15px;
}

.suggestion-item--active,
.suggestion-item {
  color: rgb(255, 140, 55);
  font-family: "landingFontTwo";
}

.suggestion-item--active-homechef,
.suggestion-item-homechef {
  color: black;
  font-family: "landingFontTwo";
  margin-bottom: 9px;
  cursor: pointer;
}

.suggestion-item--active-homechef:hover,
.suggestion-item-homechef:hover {
  background-color: rgb(236, 236, 236);
}

.fa-map-marker-alt {
  color: rgb(255, 140, 55);
  padding: 5px;
}

.update_location {
  font-family: "landingFontTwo";
  border-left: 2px solid black;
  padding: 0px 10px;
}

.hover_explore {
  flex: 1;
}

.loco {
  position: absolute;
  right: 0px;
  bottom: 0px;
  font-size: 13px;
  padding: 5px;
  cursor: pointer;
  color: orange;
  z-index: 5000;
}

.loco:hover {
  background-color: rgb(241, 241, 241);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.loco_text {
  content: "Locate Me";
  font-family: "landingFontTwo";
  margin-right: 5px;
  /* position: absolute; */
  /* top: 50%;
  transform: translateY(-50%);
  left: -60px; */
}

.hover_explore:hover {
  transform: scale(1.1) translateX(5%);
  cursor: pointer;
  margin-right: 5px;
  /* color: white; */
}

.no_translate:hover {
  transform: scale(1.1) translateX(0%) !important;
}

.update_location {
  cursor: pointer;
}

.update_location:hover {
  transform: scale(1.1);
}

.flex_cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 450px) {
  .location-btn {
    width: 100%;
    height: 65px;
    top: -16px;
  }
  .homechef-location-btn {
    height: 60px;
  }
}
