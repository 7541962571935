.menu_dish_card_cont {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  position: relative;
}

.menu_dish_card {
  width: 31.333333%;
  min-width: 320px;
  background-color: white;
  position: relative;
  height: 590px;
  margin-left: 1%;
  margin-right: 1%;
  padding: 10px;
  margin-bottom: 17px;
  position: relative;
}

.menu_dish_card:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.background_image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 47.5%;
}

@media (max-width: 1053px) {
  .menu_dish_card_cont {
    justify-content: center;
  }
}

@media (max-height: 400px) {
  .chef_image_menu {
    height: 100% !important;
    width: 100% !important;
  }
}

@media (max-width: 500px) {
  .menu_dish_card {
    height: 500px;
  }
  .background_image {
    height: 40%;
  }
  .menu_dish_card {
    height: 610px;
  }
}

@media (max-width: 947.78px) {
}
