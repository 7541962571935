.location_popup {
  position: fixed;
  width: 60%;
  left: 20%;
  top: 10%;
  padding: 50px;
  background-color: wheat;
  border-radius: 40px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  z-index: 500000;
}

.confirm_address_btn {
  padding: 10px 30px;
  background-color: white;
  border-radius: 20px;
  color: orange;
  border: 1px solid orange;
  cursor: pointer;
  font-family: "landingFontTwo";
  margin-top: 25px;
}

.confirm_address_btn:hover {
  background-color: orange;
  color: white;
  transform: scale(1.1) translate(0%);
}

@media (max-width: 500px) {
  .location_popup {
    position: fixed;
    width: 100%;
    height: 100%;
    /* left: 20%; */
    /* top: 10%; */
    top: 0;
    left: 0;
    padding: 50px;
    background-color: wheat;
    /* border-radius: 40px; */
    border-radius: 0px;
    box-shadow: rgb(99 99 99 / 20%) 0px 2px 8px 0px;
    z-index: 500000;
  }
}
