/* LANDING START */

:root {
  --background-color: #633ebf;
  --service-color: #5e39ba;
  --logo-one-color: #ff8c37;
  --logo-two-color: #0d5bc8;
  --logo-three-color: #d386fa;
  --footer-color: #1b1a18;
}

.body_h {
  background-color: var(--background-color);
  scroll-behavior: smooth;
  position: relative;
}

.text {
  width: 34%;
}

.landing {
  /* background-image: url(images/main.png); */
}

.landing_preload {
  position: absolute !important;
  object-fit: cover;
  left: 0px;
  top: 0px;
}

.container {
  height: calc(100vh - 75px);
  margin-top: 50px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
}

.text {
  margin-left: 7%;
  position: relative;
  display: inline-block;
  text-align: center;
}
.main {
  /* text-align: center; */
  font-family: "landingFont";
  text-transform: uppercase;
  letter-spacing: 1px;
  color: white;
  font-size: 50px;
  text-shadow: 5px 2px 2px rgba(255, 255, 255, 0.4);
}

/* .main:hover {
    cursor: pointer;
    transition: 400ms ease-in-out;
    transform: scale(1.2);
  } */

.submain {
  /* text-align: center; */
  font-family: "landingFontTwo";
  color: white;
  left: 0px !important;
}

.display_none {
  display: none !important;
}

.massive_height {
  background-image: linear-gradient(to bottom, #ffd66c, #fc6f44);
  padding-bottom: 90px;
  min-height: 100vh;
}

.submain > span {
  position: relative;
  display: inline-block;
  animation: wave 2s ease-in-out infinite;
  animation-delay: calc(0.1s * var(--i));
}

@keyframes wave {
  0% {
    transform: translateY(0px);
    color: #ff007f;
  }
  20% {
    transform: translateY(-20px);
  }
  40%,
  100% {
    transform: translateY(0px);
  }
}

.submain > span:hover {
  cursor: pointer;
  color: #ff007f;
}

.landing-btn {
  position: absolute;
  bottom: -60px;
  right: 50%;
  transform: translate(50%, 50%);
  width: 100%;
  bottom: -50px;
}

.btn_btn {
  padding: 20px;
  border-radius: 20px;
  color: var(--logo-one-color);
  background-color: white;
  border: solid var(--logo-one-color);
}

.btn_btn:hover {
  background-color: var(--logo-one-color);
  color: white;
  transition: 400ms ease-in-out;
}

/* LANDING END */

/* SERVICE START */
.left_chef {
  width: 800px;
  transform: scaleX(-1);
  position: absolute;
  top: -50px;
  left: -60px;
  animation: floating 3s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px) scaleX(-1);
  }
  50% {
    transform: translate(0, 15px) scaleX(-1);
  }
  100% {
    transform: translate(0, -0px) scaleX(-1);
  }
}

.service {
  position: relative;
  min-height: 600px;
}

/* .burger {
    position: absolute;
    width: 50px;
    height: 50px;
    color: white;
    right: 100px;
    top: 50px;
    transform: rotate(45deg);
  } */

.left_cont {
  min-height: 100px;
  position: relative;
}

.service_container {
  text-align: center;
  position: absolute;
  background-color: white;
  border: solid #a471d5 5px;
  border-radius: 30px;
  position: absolute;
  top: -60px;
  width: 40%;
  overflow: hidden;
  min-height: 380px;
}

.wave {
  position: absolute;
  top: 0px;
  right: 0px;
}

.circles {
  position: absolute;
  right: -160px;
  top: -50px;
}

.hands {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translate(-50%, 40%);
  width: 50%;
}

.hands_move {
  animation: handRaise 2s forwards ease-in-out;
}

.bubbles {
  position: absolute;
  top: 0px;
  right: 50%;
  width: 35%;
  max-width: 150px;
}

.curve_lines {
  position: absolute;
  bottom: -120px;
  left: -110px;
}

/* put js for this animation and put it only on actives class or something */
@keyframes handRaise {
  0% {
    transform: translate(-50%, 40%);
  }
  100% {
    transform: translate(-50%, 10%);
  }
}

.service_text {
  color: var(--service-color);
  font-family: "p-bold";
  font-size: 1.25rem;
}

/* SERVICE END */
/* VALUE-PROP B START */

.container_b {
  text-align: center;
  margin-top: 50px;
  width: 95%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  text-align: center;
}
.section_title_h {
  font-family: "landingFontTwo";
  font-size: 2rem;
  color: white;
  z-index: 5;
}

.card-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.card_logo_h {
  min-width: 280.41px;
  width: 23%;
  height: 450px;
  padding: 30px;
  background-color: #502dab;
  border-radius: 30px;
  border: solid #6b47cd 2px;
}

.center_image {
  display: flex;
  justify-content: center;
  height: 100%;
}

.slogan {
  text-transform: uppercase;
  font-family: "section-font";
  color: white;
}

.logo_one {
  background-color: #ff8c36;
}

.logo_two {
  background-color: #0d5bc8;
}

.logo_three {
  background-color: #d286fa;
}

.image-wrapper {
  margin-bottom: 20px;
  display: inline-block;
  border-radius: 30px;
  padding: 20px;
}
.photo_logo {
  width: 60px;
  height: 60px;
}

.card-container p {
  font-family: "p-font";
  color: white;
  margin-top: 20px;
  flex: 1;
}

.column {
  flex-direction: column;
}

.align-start {
  align-items: flex-start !important;
  /* justify-content: flex-start; */
}

.variety {
  margin-bottom: 40px;
}
/* .column-arrange {
    display: flex;
    flex-direction: column;
    height: 100%;
  } */
/* VALUE-PROP B END */
/* VALUE-PROP S START */

.container_s {
  display: flex;
  background-color: var(--service-color);
  padding: 60px 10px;
  justify-content: center;
  width: 100%;
  border-radius: 80px;
  position: relative;
}
.top_right_star {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 10px;
  border: solid white 1px;
  border-radius: 20px;
  width: fit-content;
}

.top_right_star > p {
  display: inline-block;
  color: white;
  font-family: "p-font";
  font-size: 12px;
}
.fa-star {
  display: inline-block;
  color: white;
  margin-right: 10px;
  font-size: 11px;
}

.chef_image_s {
  height: 100%;
  width: 50%;
  object-fit: cover;
  position: absolute;
  top: -100px;
  left: 63%;
  transform: translateX(-50%);
  z-index: 5;
}

.card_s {
  width: 25%;
  height: 500px;
  position: relative;
}

.container_s .card_s:not(:last-child) {
  margin-right: 40px;
}

.center_image {
  height: 55%;
  position: relative;
}

.not_cropped {
  border-radius: 100px;
}

.card_s_one {
  background: #f8a12e;
  padding: 20px;
  border-radius: 50px;
  position: relative;
}

.card_s_two {
  background: #1266d8;
  padding: 20px;
  border-radius: 50px;
}

.card_s_three {
  background: #353638;
  padding: 20px;
  border-radius: 50px;
}

.container_v {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text_s {
  background: white;
  position: absolute;
  top: 50%;
  left: 0px;
  margin: 0px 10px;
  padding: 20px;
  border-radius: 40px;
  height: 48%;
}

.text_s > h1 {
  font-family: "p-bold";
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.text_s > p {
  font-family: "p-font";
  font-size: 0.8rem;
}

.extra_margin_b {
  margin-bottom: 80px;
  z-index: 5;
}

.fa-arrow-alt-circle-left.landing_arrow {
  cursor: pointer;
  color: white;
  font-size: 50px;
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
}

.fa-arrow-alt-circle-right.landing_arrow {
  cursor: pointer;
  color: white;
  font-size: 50px;
  position: absolute;
  top: 50%;
  right: 40px;
  transform: translateY(-50%);
}

.fa-arrow-alt-circle-right:hover,
.fa-arrow-alt-circle-left:hover {
  transform: scale(1.1) translateY(-50%);
  transition: 100ms ease-in-out;
  display: none;
}

.yellow_wave {
  position: absolute;
  width: 40%;
  z-index: 1;
  left: 50%;
  top: 0px;
  transform: translateY(-100%) translateX(-50%);
}

/* .purple_wave {
    position: absolute;
    z-index: 1;
    left: 30%;
    bottom: 0px;
    transform: translateX(-50%) translateY(100%);
  } */

/* CHEF STORY SECTION END */

.gallery {
  display: flex;
  height: 600px;
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  background: white;
  border-radius: 40px;
}

.left_main_photo {
  background-image: url(images/Food\ background.jpg);
  position: relative;
  height: 98%;
  margin-top: 0.5%;
  width: 49%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  border-radius: 40px 0px 0px 40px;
  text-align: center;
}

.left_main_photo > h1 {
  font-family: "reey";
  position: absolute;
  top: 50px;
  width: 100%;
  font-size: 40px;
  font-weight: 100;
}

.right_gallery {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.top_background {
  width: 99%;
  position: relative;
  height: 48%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 1%;
  background-image: radial-gradient(circle, gray, black);
  border-radius: 0px 40px 0px 0px;
  text-align: center;
}

.bottom_background {
  position: relative;
  width: 99%;
  height: 49%;
  margin-top: 1%;
  margin-bottom: 1%;
  margin-right: 1%;
  background-image: linear-gradient(to bottom, white, #b2b4b8);
  border-radius: 0px 0px 40px 0px;
  text-align: center;
}

.gallery span {
  font-size: 13px;
  letter-spacing: 2px;
}

.cont {
  position: relative;
  z-index: 3;
}

.feat_two {
  width: 40%;
  position: absolute;
  bottom: 23%;
  left: 10%;
}

.feat_three {
  width: 40%;
  position: absolute;
  bottom: 23%;
  right: 10%;
}

.feat_one {
  width: 70%;
  position: absolute;
  top: 60%;
  left: 60%;
  transform: translate(-50%, -50%);
}

.top_header {
  font-family: "reey";
  color: white;
  width: 100%;
  margin-top: 20px;
  /* letter-spacing: 1px; */
  /* position: absolute;
    top: 50px;
    right: 15%; */
}

.bottom_header {
  width: 100%;
  margin-top: 10px;
  font-family: "reey";
  color: #815bf2;
  /* position: absolute;
    bottom: 90px;
    right: 50%;
    font-size: 20px;
    transform: translateX(50%); */
  /* position: absolute;
    top: 50px;
    left: 5%; */
}

.image_background_b {
  height: 100%;
  background-image: url(images/—Pngtree—chicken\ salad_4983788.png);
  background-position: center;
  background-size: 55%;
}

.see-dish {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.image_background_b_two {
  height: 100%;
  background-image: url(images/—Pngtree—chicken\ salad_4983788.png);
  background-position: center;
  background-size: 50%;
}
.brief_description {
  font-size: 15px;
  font-family: "section-font";
}

.bd_one {
  color: #a4762b;
  position: absolute;
  bottom: 30px;
  right: 20px;
}

/* .bd_three {
    color: #634fac;
    position: absolute;
    font-family: "section-font";
    bottom: 30px;
    left: 5%;
  } */

.bd_two {
  color: #717071;
  position: absolute;
  font-family: "section-font";
  bottom: 20px;
  left: 5%;
}

.purple_wave_two {
  z-index: 1;
  position: absolute;
  bottom: 0px;
}

.featured_dishes {
  position: relative;
}

/* GRIDS */
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}

.col-3 {
  width: 25%; /*PUT this after a flex to ensure width is static and not dynamic according to content.*/
}

.col-4 {
  width: 33.33%;
}

.col-5 {
  width: 41.66%;
}
.col-6 {
  width: 50%;
}

.col-7 {
  width: 58%;
}

.col-8 {
  width: 66.66%;
}

.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}

.col-11 {
  width: 91.66%;
}

.col-12 {
  width: 100%;
}

/* COMPLETE FOR MID-LARGE LENGTH */
@media (max-width: 1200px) {
  .left_chef {
    width: 700px;
    top: 50px !important;
  }

  .container {
    margin-top: 30px;
    width: 97%;
  }

  .text {
    width: 42% !important;
    margin-left: 1%;
  }
  .main {
    font-size: 50px;
    display: inline-block;
  }
  .submain {
    font-size: 18px;
    display: inline-block;
  }

  .chef_image {
    right: 20px;
  }

  .service > .right_block {
    position: absolute;
  }

  .service_container {
    width: 100% !important;
  }
}

@media (max-width: 1359px) {
  .top_right_star {
    position: absolute;
    bottom: 55%;
    left: 5%;
    min-width: 67px;
  }

  .chef_image {
    z-index: 5;
    left: 50%;
    transform: translateX(-50%);
  }

  .chef_image_s {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: -75px;
  }
}

@media (max-width: 1214px) {
  .card_s_one {
    width: 60%;
    margin-right: 0px !important;
  }

  .card_s_two,
  .card_s_three {
    display: none;
  }
}

@media (max-width: 1054px) {
  .left_chef {
    width: 500px;
    top: 50px !important;
  }
}
/* I-PAD SIZE! */
@media (max-width: 970px) {
  .container {
    margin-top: 30px;
  }
  .text {
    width: 49% !important;
    margin-left: 2%;
  }
  .main {
    font-size: 42px;
    display: inline-block;
  }
  .submain {
    font-size: 20px;
    display: inline-block;
  }

  .service > .right_block {
    position: absolute;
  }

  .service_container {
    width: 100% !important;
  }
}

/* FOR IPAD AND IPHONE MAINLY FOCUS ON THIS*/
@media (max-width: 942.5px) {
  .nav_right > a:not(:last-child) {
    margin-right: 35px;
  }

  .left_chef {
    width: 500px;
    top: 170px !important;
  }
  .container {
    margin-top: 85px;
  }
  .text {
    width: 60%;
  }
  .main {
    display: none;
  }
  .landing-btn {
    right: 55%;
  }

  .service > .right_block {
    position: absolute;
  }

  .service_container {
    width: 100% !important;
  }

  .hands {
    width: 80%;
  }

  .card-container {
    flex-direction: column;
  }

  .card_logo {
    min-width: 400px;
  }

  .card_logo:not(:last-child) {
    margin-bottom: 40px;
  }

  .gallery {
    width: 90%;
  }
}

@media (max-width: 834px) {
  .container {
    margin-top: 30px;
  }

  .landing-btn {
    width: 50%;
  }
  .location-btn {
    width: 100%;
  }

  .featured_dishes {
    display: none;
  }

  .location-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 10px !important;
  }

  .text {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .card_s_one {
    width: 90%;
  }
  .search_bar {
    display: none;
  }
  .text_s {
    height: 49%;
    width: 94%;
    padding: 8px;
  }
  .top_right_star {
    min-width: 62px;
  }
  .food_card_l {
    margin-top: 30px;
  }
}

.dishes_promo {
  padding: 50px;
  display: flex;
  justify-content: space-around;
}

.dish_dets {
  position: absolute;
  bottom: 35%;
  transform: translate(-50%, 50%);
  left: 50%;
  width: 70%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}

.mini_dets {
  position: absolute !important;
  bottom: 0px;
  width: 100%;
}

.fullwidth {
  width: 100%;
  padding: 10px;
}

.price_tag_l {
  /* margin-top: 30px; */
  font-family: "landingFontTwo";
}

.div_l {
  flex: 1;
}

.line_1_wrapper > p {
  position: relative;
  text-align: center;
  opacity: 1;
  /* bottom: -60px; */
  /* left: 0px; */
  padding: 20px;
  color: black;
  min-width: 350px;
  letter-spacing: 5px;
  font-size: 25px;
  overflow: hidden;
  font-family: "landingFontTwo";
  color: orange;
  margin-bottom: 40px;
}

.line_1_wrapper {
  position: relative;
}

.dish_name_l {
  font-size: 17px;
}

.food_card_l {
  min-width: 230px;
  text-align: center;
  width: 200px;
  height: 300px;
  font-family: "landingFontTwo";
  color: white;
  border-radius: 20px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  margin-left: 5px;
  margin-right: 10px;
  background-color: #502dab;
  border-radius: 30px;
  border: solid #6b47cd 2px;
}

.btn_orange {
  color: orangered;
  /* background-color: white; */
  font-family: "landingFontTwo";
  padding: 10px 20px;
  border: 2px solid orangered;
  border-radius: 20px;
  margin: 10px;
  cursor: pointer;
  background-color: rgb(255, 218, 193);
}

.btn_blue {
  color: rgb(0, 174, 255);
  /* background-color: white; */
  font-family: "landingFontTwo";
  padding: 10px 20px;
  border: 2px solid turquoise;
  border-radius: 20px;
  margin: 10px;
  cursor: pointer;
  background-color: rgb(226, 255, 248);
}

.btn_orange:hover,
.btn_blue:hover {
  transform: scale(1.05);
}

.dish_img_l {
  border-radius: 20px;
  width: 160px;
  height: 160px;
  object-fit: cover;
  position: absolute;
  top: -30px;
  right: 50%;
  transform: translateX(50%);
}

.cuisine_label {
  background-color: rgb(255, 218, 193);
  color: orangered;
  border: 1px solid orangered;
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "landingFontTwo";
  font-size: 12px;
  margin-top: 50px;
  margin-bottom: 10px;
  width: fit-content;
}

.baker_label {
  background-color: rgb(226, 255, 248);
  color: rgb(0, 174, 255);
  border: 1px solid rgb(0, 255, 229);
  padding: 5px 10px;
  border-radius: 5px;
  font-family: "landingFontTwo";
  font-size: 12px;
  margin-top: 50px;
  margin-bottom: 10px;
  width: fit-content;
}

.special_size {
  height: 190px;
  width: 190px;
}

.special_size_l {
  height: 290px;
  width: 230px;
  top: -80px;
}
