.profile_padding {
  padding: 30px;
  padding-left: 50px;
  padding-right: 50px;
  position: relative;
}

.header_profile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "landingFontTwo";
}

.apt_input_p {
  padding: 15px;
  border-radius: 25px;
  border: solid rgb(223, 223, 223) 1px;
  font-family: "landingFontTwo";
  color: black;
  font-size: 15px;
  margin-left: 40px;
  min-width: 335px;
}

.header_profile p {
  font-size: 13px;
}

.update_profile_changes_btn {
  background-color: #fb613f;
  color: white;
  padding: 10px 40px;
  border-radius: 20px;
  font-size: 13px;
  cursor: pointer;
}

.top_section_cont {
  display: flex;
  align-items: center;
  margin-top: 40px;
  flex-wrap: wrap;
}

.profile_card {
  width: 200px;
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  margin-right: 40px;
}

.profile_img {
  width: 62%;
  height: 54%;
  border-radius: 100%;
  position: relative;
}

.edit_profile_img {
  position: absolute;
  background-color: black;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  top: 10px;
  cursor: pointer;
}

.profile_card h1 {
  font-family: "landingFontTwo";
  font-size: 20px;
}

.fa-pencil-alt {
  color: white;
  font-size: 15px;
}

.fa-star.profile_star {
  color: #e5e5e5;
  margin-right: 5px;
}

.chef_logo {
  width: 100px;
  height: 100px;
}

.chip {
  width: 40px;
  fill: #dc83ae;
  height: 40px;
}

.loyalty_card {
  padding: 30px;
  height: 250px;
  width: 420px;
  background: linear-gradient(
    137deg,
    rgba(180, 46, 146, 1) 10%,
    rgba(248, 77, 59, 1) 66%,
    rgba(255, 167, 85, 1) 100%
  );

  font-family: "landingFontTwo";
  border-radius: 40px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  text-align: left;
  position: relative;
  min-width: 400px;
  display: none;
  /* BASE OF RATINGS?  */
}

.loyalty_title {
  color: white;
  font-size: 20px;
  margin-top: 10px;
  font-family: "section-font";
}

.loyalty_title > span {
  font-size: 10px;
  color: rgb(255, 144, 144);
}

.card_holder_section {
  position: absolute;
  bottom: 30px;
  left: 30px;
  color: white;
}

.card_logo_p {
  position: absolute;
  right: 30px;
  bottom: 0px;
}

.card_holder_title {
  font-size: 13px;
}
.name_card {
  margin-top: 10px;
  font-size: 20px;
  font-family: "section-font";
}

.header_table {
  width: 120px;
  height: 90px;
  padding: 10px;
  font-family: "landingFontTwo";
  border: solid rgb(224, 224, 224) 1px;
}

.time_table {
  margin-top: 40px;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  white-space: nowrap;
}

table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}
table tr {
  display: table-cell;
}
table tr td {
  display: block;
}

.header_table h1 {
  font-family: "landingFontTwo";
  margin-top: 20px;
}

.profile_all_inputs {
  margin-top: 40px;
  text-align: left;
}

.name_input_profile {
  width: 30%;
}
.name_input_profile p {
  margin-bottom: 20px;
  margin-left: 10px;
}

.name_input_p {
  width: 100%;
  padding: 15px;
  border-radius: 25px;
  border: solid rgb(223, 223, 223) 1px;
  font-family: "landingFontTwo";
  color: black;
  font-size: 15px;
}

.contact_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: relative;
}

.header_profile {
  font-family: "section-font";
  font-size: 20px;
  padding: 20px 0px;
  color: #6b696a;
}

.profile_note {
  width: 100% !important;
  max-width: 100% !important;
  text-align: left !important;
}

.address_input_profile {
  width: 70%;
}
.zip_input_profile {
  width: 20%;
}

.address_details {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logout_btn {
  padding: 10px 70px;
  background-color: rgb(255, 172, 64);
  border-radius: 30px;
  position: absolute;
  right: 0px;
  bottom: -30px;
  font-family: "landingFontTwo";
  margin: 10px;
  font-size: 18px;
}

@media (max-width: 1149px) {
  .time_table {
    justify-content: flex-start;
  }
}

@media (max-width: 1024px) {
  .loyalty_card {
    margin-top: 20px;
  }
  .header_table {
    width: 70px;
    height: 45px;
    padding: 5px;
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .loyalty_card {
    display: none;
  }
  .contact_details {
    flex-direction: column;
  }
  .name_input_profile {
    width: 100%;
    margin-top: 20px;
  }
  .address_details {
    flex-direction: column;
  }
  .phone_input_profile {
    width: 100%;
    margin-top: 20px;
  }

  .apt_input_p {
    min-width: 50px;
  }
}
