.homechef_main {
  color: #2d2727;
  display: flex;
  background-color: #2d2727;
  height: 90vh;
  border-radius: 25px;
}

.hidden_container_trick {
  overflow: hidden;
  position: relative;
}

.left_container_seller {
  width: 60%;
  height: 100%;
  position: relative;
  text-align: start;
  padding-left: 10%;
  padding-top: 5%;
}

.homechef_big_div {
  padding-bottom: 100px;
}

.left_container_seller_m {
  width: 60%;
  height: 100%;
  position: relative;
  text-align: start;
  padding-left: 10%;
}

.left_container_general_s {
  width: 100%;
  position: relative;
  text-align: start;
  padding-left: 10%;
  padding-right: 10%;
  padding-bottom: 50px;
  top: 100px !important;
}

.right_container_seller {
  width: 40%;
  height: 100%;
  position: relative;
}
/* FREEPIK */
.freepik_pasta {
  position: absolute;
  left: 60%;
  top: 0px;
  width: 30%;
  min-width: 400px;
  transform: translateY(-30%);
}

.freepik_leaf {
  position: absolute;
  right: -20px;
  bottom: 40%;
  width: 70px;
}

.freepik_apple {
  position: absolute;
  right: 5%;
  bottom: 30px;
  width: 60px;
}
.freepik_apple_2 {
  position: absolute;
  right: 7%;
  top: -30px;
  width: 60px;
  transform: scale(-1);
}
/* TIMELINE ICONS */
.right_container_seller h1 {
  position: relative;
  font-family: "landingFontTwo";
  color: white;
  margin-bottom: 40px;
  font-size: 20px;
}

.application_timeline {
  position: absolute;
  left: 10px;
  bottom: 20px;
  color: white;
  font-family: "landingFontTwo";
  font-size: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}

.icon_timeline {
  background-size: contain !important;
  padding: 6px 6px;
  width: 23px;
  height: 23px;
  margin-right: 10px;
  border-radius: 10px;
  background-color: #3c3734;
}

.fas.fa-file-alt {
  color: #ffd56c;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.application_timeline > div {
  margin-bottom: 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.imgs_container {
  position: relative;
  text-align: start;
}

.background_border {
  padding: 6px 6px;
  width: 28.52px;
  height: 29px;
  position: absolute;
  left: -3px;
  border-radius: 20px;
  background-color: transparent;
  border: solid #3c3734 1px;
}

.background_border.form {
  height: 30px;
}

/* LHS Form and Slogan */

.left_container_seller h1 {
  color: white;
  font-family: "p-bold";
  font-size: 45px;
  position: relative;
}

.left_container_seller > h1 > .general_text {
  font-family: "p-font";
}
.left_container_seller > h1 > .dollar {
  position: relative;
  bottom: -13px;
  left: -5px;
  font-size: 42px;
  font-family: "landingFontTwo";
  color: #f3dfa6;
}

.donut {
  width: 35px;
}

/* BENEFITS SECTION */
.seller_benefits {
  text-align: start;
  margin-top: 400px;
  font-family: "landingFontTwo";
}

.seller_benefit_cards_cont {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
}
.seller_benefits_title {
  margin-bottom: 40px;
}

.faq_flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.section_title {
  font-size: 30px;
  font-family: "landingFontTwo";
  text-align: center;
  margin-bottom: 30px;
  text-decoration: underline;
}

@media (max-width: 937px) {
  .faq_flex {
    justify-content: center;
  }
  /* set width100! */
}

@media (max-width: 768px) {
  .form_slogan {
    font-size: 40px !important;
    margin-top: 50%;
  }
}

@media (max-width: 450px) {
  .form_slogan {
    font-size: 28px !important;
    margin-top: 10%;
  }
  .donut {
    width: 18px;
  }
  .dollar {
    font-size: 25px !important;
    left: -2px !important;
    bottom: -10px;
  }
  .form_row_container {
    flex-direction: column;
  }
  .form_col {
    width: 100%;
  }
  .application_timeline {
    bottom: 0px !important;
  }
}

@media (max-height: 414px) {
  .form_slogan {
    font-size: 20px !important;
    width: 100%;
    position: absolute;
    bottom: -90px;
  }
  .form_slogan br {
    display: none;
  }
  .donut {
    width: 13px;
  }
  .dollar {
    font-size: 20px !important;
  }
}
